import { Routes, Route } from "react-router-dom";
import { About, Contact, Home, Projects, Skills } from "../pages";


const AllRoutes = () => {
    return ( 
        <>
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/projects" element={<Projects />}></Route>
                <Route path="/skills" element={<Skills />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/about" element={<About />}></Route>
            </Routes>
        </>
     );
}
 
export default AllRoutes;