import { motion, useScroll,  useTransform } from "framer-motion";
import { useRef } from "react";

const Single = ({item}) => {
    const ref = useRef(null);
    
    const { scrollYProgress } =  useScroll({
        target: ref,
        // offset:["end start", "start start"]
    });
   

    const y = useTransform(scrollYProgress, [0, 1], [ -100, 50]);

    return (
        <div>
            <div className="container">
                <div className="wrapper">
                    <div className="imageContainer">
                        <img src={item.img} alt="" ref={ref}/>
                    </div>
                    <motion.div className="textContainer" style={{ y }}>
                        <h2>{item.title}</h2>
                        <ul className="programs">
                            {item.programs.map((program, index) =>(
                                <li key={index}>{program}</li>
                            ))}
                        </ul>
                        <p>{item.desc}</p>
                        <a href={item.link} target="_blank">
                            <button>View Demo</button>
                        </a>
                        
                    </motion.div>
                </div>
                
            </div>

        </div>
    )
}
export default Single;