import { useState } from "react";
import { motion } from "framer-motion";

import "./sidebar.scss";
import Togglebutton from "./Togglebutton";
import Links from "./Links";

const Sidebar = () => {

    //state for variants
    const [open, setOpen] = useState(false);

    // Variants
    const variants = {
        open:{
            clipPath: "circle(1200px at 50px 50px)", 
            transition: {
                type:"spring",
                stiffness: 20,
            },
        },
        closed: {
            clipPath: "circle(20px at 50px 47px)",
            transition:{
                delay: 0.1,
                type: "spring",
                stiffness: 400,
                damping: 40,
            },
        },
    };
    return ( 
        <motion.div 
            className="sidebar"
            animate={open ? "open" : "closed"}
        >
            <Togglebutton setOpen={setOpen} open={open}/>
            <motion.div className="bg" variants={variants}>
                <Links setOpen={setOpen} open={open}/>
            </motion.div>
            
        </motion.div>
     );
}
 
export default Sidebar;