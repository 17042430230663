import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import pdf from "../assets/resume - Copy.pdf";

const Links = ({setOpen, open}) => {

    // variants for the div
    const variants = {
        open:{
            transition:{
                staggerChildren:0.2,
            }
        },
        closed:{
            transition:{
                staggerChildren:0.05,
                staggerDirection: -1,
            },
        },
    };

    // variants for each item
    const itemVariants = {
        open: {
            y:0,
            opacity: 1,
        },
        closed: {
            y: 50,
            opacity: 0,
        },
    };

    return ( 
        <motion.div className="links" variants={variants}>
            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to="/" onClick={() => setOpen(!open)}>HOME</Link>
            </motion.div>
            
            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to="/projects" onClick={() => setOpen(!open)}>PROJECTS</Link>
            </motion.div>
            
            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to="/skills" onClick={() => setOpen(!open)}>SKILLS</Link>
            </motion.div>

            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to="/contact" onClick={() => setOpen(!open)}>CONTACT</Link>
            </motion.div>
            
            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to="/about" onClick={() => setOpen(!open)} >ABOUT</Link>    
            </motion.div>
            <motion.div variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <a href={pdf} target="_blank">RESUME</a>   
            </motion.div>
            
        </motion.div>
     );
}
 
export default Links;