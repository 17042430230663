import "./home.scss";
import heroPic from "../assets/Hero.png"
import pdf from "../assets/resume - Copy.pdf";

import {  motion } from "framer-motion";
import { Link } from "react-router-dom";

import useTitle from "../hooks/useTitle";


const Home = () => {
    // document title
    useTitle("Home");

    // text Variant
    const textVariant = {
        initial:{
            x: -500,
            opacity: 0,
        },
        animate:{
            x: 0,
            opacity: 1,
            transition:{
                duration: 1,
                staggerChildren: 0.1,
            },
        }, 
    }; 

    // slider variant
    const sliderVariant = {
        initial: {
            x: 0,
        },
        animate:{
            x: "-200%",
            transition: {
                repeat: Infinity,
                repeatType: "mirror",
                duration: 3,
            },
        },
    };

    return ( 
        <section>
            <div className="hero">
                <div className="wrapper">
                    <motion.div 
                        className="textContainer" 
                        variants={textVariant} 
                        initial="initial" 
                        animate="animate"
                    >
                        <motion.h2 variants={textVariant}>MINENHLE BARRY DLAMINI</motion.h2>
                        <motion.h1 variants={textVariant}>FRONT-END DEVELOPER</motion.h1>
                        <motion.div className="buttons" variants={textVariant}>
                            <motion.button variants={textVariant}>
                                <Link to="/projects">
                                    View Projects
                                </Link>
                            </motion.button>
                            <motion.button variants={textVariant}>
                                <Link to="/contact">
                                    Contact Me
                                </Link>
                            </motion.button>
                            <motion.button variants={textVariant}>
                                <a href={pdf} target="_blank">Resume</a>
                            </motion.button>
                            
                        </motion.div>
                    </motion.div>
                    <motion.div className="imageContainer">
                        <motion.img src={heroPic} alt="Computer Image" drag dragConstraints={{ left:60, right:0, top: 0, bottom: 0}}/>
                    </motion.div>
                </div>
                <motion.div className="slidingText" variants={sliderVariant} initial="initial" animate="animate">
                    Hello World
                </motion.div>
            </div>
        </section>
     );
}
 
export default Home;

