import "./about.scss";
import { useState } from "react";
import useTitle from "../hooks/useTitle";
import { Link } from "react-router-dom";

const About = () => {
    // document title
    useTitle("About");

    //If state is false short version appears
    const [size, setSet] = useState(false);
    return ( 
        <section>
            <div className="aboutContainer">
                <h1>ABOUT ME</h1>
                { size ? <span onClick={() => setSet(!size)}>SHOW SUMMARY</span> : <span onClick={() => setSet(!size)}>SHOW FULL VERSION</span>}
                <div className="textContainer">
                    { size 
                    ?
                        <p>
                            My name is Minenhle Barry Dlamini, a 28-year-old front-end developer residing in the East Rand of Johannesburg.
                            <br />
                            <br />
                            My journey into programming began during my studies in BCom Informatics at the University of Pretoria in 2017, focusing on system development for commercial enterprises. It was there that I was introduced to my first programming language, C#. Since then, the process of solving problems through programming and developing solutions has become a passion of mine. I expanded my skills to include HTML, CSS, JavaScript, and the ASP.NET framework, progressing to develop MVC user interfaces. This experience sharpened my problem-solving abilities and overall programming logic.
                            <br />
                            <br />
                            My academic path also immersed me in the systems development life cycle (SDLC), encompassing tasks such as use-case scenarios, creating Entity-Relationship Diagrams (ERDs), and building databases using MySQL. Collaborative projects during my studies enhanced my teamwork skills, proving invaluable in professional settings.
                            <br />
                            <br />
                            Modules in Statistics and Accounting further honed my analytical and problem-solving capabilities. Unfortunately, financial constraints interrupted my studies, prompting me to explore other fields with limited success.
                            <br />
                            <br />
                            In 2020, my passion for development reignited when a close friend embarked on self-teaching programming. I began coding independently, finding fulfillment in the autonomy of my learning journey. I ventured into React, building applications like to-do lists and expanding my expertise with TypeScript, SASS, and Tailwind CSS. Today, I utilize libraries such as ReactJS and ExpressJS, alongside cloud technologies, to develop modern web applications for both business and personal use.
                            <br />
                            <br />
                            To get a Global Context of my <u><Link to="/skills">skills</Link></u> and <u><Link to="/projects">projects</Link></u>. <em>Follow the links</em>
                            <br />
                            <br />
                            My journey thus far has molded me into a skilled developer known for adaptability, reliability, and a steadfast commitment to continual growth, both professionally and personally. Looking ahead, I aspire to collaborate with organizations that share my passion for leveraging software to make a meaningful impact on a global scale. If this resonates with your organization’s values, I invite you to <u><Link to="/contact">connect</Link></u> with me.
                        </p> 
                    : 
                        <p>
                            I'm Minenhle Barry Dlamini a 28 year old front-end developer from the East Rand of Johannesburg.
                            <br />
                            <br />
                            In 2020, my passion for development reignited when a close friend embarked on self-teaching programming. I also began coding independently, finding fulfillment in the autonomy of my learning journey. I ventured into React, building applications like to-do lists and expanding my expertise with TypeScript, SASS, and Tailwind CSS. Today, I utilize libraries such as ReactJS and ExpressJS, alongside cloud technologies, to develop modern web applications for both business and personal use.
                            <br />
                            <br />
                            Web development isn't easy but I love it and will solve any problem to make it work. 
                            <br />
                            <br />
                            To get a Global Context of my <u><Link to="/skills">skills</Link></u> and <u><Link to="/projects">projects</Link></u>. <em>Follow the links</em>
                            <br />
                            <br />
                            My journey thus far has molded me into a skilled developer known for adaptability, reliability, and a steadfast commitment to continual growth, both professionally and personally. Looking ahead, I aspire to collaborate with organizations that share my passion for leveraging software to make a meaningful impact on a global scale. If this resonates with your organization’s values, I invite you to <u><Link to="/contact">connect</Link></u> with me.
                        </p>
                    }               
                </div>

            </div>
            
        </section>
     );
}
 
export default About;
