import "./Navbar.scss"
import { motion } from "framer-motion";
import Linkedin from "../assets/linkedin.png";
import Sidebar from "./SIdebar";
import { Link } from "react-router-dom";

const Navbar = () => {
    return ( 
        <header className="navbar">
            <Sidebar />
            <div className="wrapper">
                <motion.span
                    initial={{opacity:0, scale:0.5}}
                    animate={{opacity:1, scale:1}}
                    transition={{duration:0.5}}
                >
                    <Link to="/"><i className="bi bi-house-fill"></i></Link>
                    
                </motion.span>

                <motion.div 
                    className="social"
                    initial={{opacity:0, scale:0.5}}
                    animate={{opacity:1, scale:1}}
                    transition={{duration:0.5}}
                >
                    <a href="https://www.linkedin.com/in/minenhle-dlamini-839445313/" target="_blank">
                        <img src={Linkedin} alt="" />
                    </a>
                </motion.div>
            </div>
        </header>
     );
}
 
export default Navbar;