import Single from "./SIngle";

import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

// import Parallax from "../components/Parallax/Parallax";

import amahle from "../assets/Projects/amahleamashenge.png";
import days4fitness from "../assets/Projects/days4fitness.png";
import picknbook from "../assets/Projects/picknbook.png";
import spinwin from "../assets/Projects/spinwin.svg";
import tgemovies from "../assets/Projects/tgemovies.png";
import k1a from "../assets/Projects/k1a.png";
// import urbanesoftware from "../assets/Projects/urbanesoftware.png";

import "./projects.scss"

import useTitle from "../hooks/useTitle";

const items = [
    {
        id: 1,
        title: "Pick 'n Book",
        img: picknbook,
        programs: ["React JS", "HTML", "Tailwind CSS", "Express JS", "JSON Server Auth", "Figma"],
        desc: "Pick 'n Book is a sophisticated e-commerce platform specializing in ebooks, distinguished by its sleek user interface and powered by an Express JS 'fakeAPI' for efficient data retrieval. The platform seamlessly integrates a robust cart and ordering system, providing users with a seamless purchasing experience. Each customer enjoys a personalized profile, enhancing user interaction and customization options. Pick 'n Book is designed to elevate the ebook shopping experience, offering intuitive navigation and comprehensive features tailored to modern digital readers.",
        link: "https://picknbook.co.za"
    },
    {
        id: 2, 
        title: "K1 Autowash",
        img: k1a,
        programs: ["React JS", "HTML",  "Sass", "Adobe XD"],
        desc: "This is the landing page for K1 Autowash, a local car wash facility in my area. It showcases my grasp of design fundamentals, responsive design and colour theory. As the ability to transform Adobe XD designs into functional production ready projects. I programmed it using React JS and Typescript.",
        link: "https://k1autowash.co.za"
    },
    {
        id: 3, 
        title: "Days 4 Fitness",
        img: days4fitness,
        programs: ["React JS", "HTML",  "Sass", "Figma"],
        desc: "Days 4 fitness, this is the landing page for a personal trainer in my local area. It demonstrates my expertise in design principles, responsive design, and color theory, along with my proficiency in translating Figma designs into fully functional, production-ready projects. I implemented it using React JS and Tailwind CSS.",
        link: "https://days4fitness.netlify.app/"
    },
    {
        id: 4,
        title: "TGE Movies",
        img: tgemovies,
        programs: ["React JS", "HTML", "Tailwind CSS", "Figma"],
        desc: "TGE Movies is your comprehensive movie companion app, pulling data from an API to provide up-to-date details on films. Using context, it remembers your favorites & stores them locally for easy access. The app boasts a powerful search function to find movies as soon as you start typing, while smooth routing ensures seamless navigation. Whether you're a casual viewer or dedicated cinephile, TGE Movies enhances your movie-watching experience with its intuitive design and personalized features. Never lose track of your favourite films again",
        link: "https://tgemovies.netlify.app/"
    },
    {
        id: 5,
        title: "Amahle Amashenge",
        img: amahle,
        programs: ["React JS", "HTML", "CSS", "Figma"],
        desc: "Amahle Amashenge is a sleekly designed website created to establish an online presence for a local company in my area. Featuring engaging animations and built with React Router for seamless navigation, the site showcases the company's profile prominently. Visitors can explore comprehensive information about the company's services, values, and achievements, making it a compelling digital platform for potential clients and stakeholders alike.",
        link: "https://amahleamashenge.netlify.app/"
    }
];



const Projects = () => {
    // document title
    useTitle("Projects");
    
    // reference of section
    const ref = useRef();
    const divref = useRef(null);
    
;
    // // using useScroll from framer motion
    // const {scrollY2Progress} = useScroll({target: ref, offset:["end end", "start start"]});

    // // useSpring Hook from progress bar
    // const scaleX = useSpring(scrollY2Progress, {
    //     stiffness: 100,
    //     damping: 30
    // });

    // Spin and Win
    const { scrollYProgress } =  useScroll({
        target: divref,
        // offset:["start start", "end start"]
    });

    const y = useTransform(scrollYProgress, [0, 1], [ -100, 50]);
    return ( 
        <section>
            <div className="projects" ref={ref}>
                <div className="progress">
                    <h1>PROJECTS</h1>
                    {/* <motion.div style={{scaleX}} className="progressBar"></motion.div> */}
                </div>
                
                <div className="projectContainer">
                    <div>
                        <div className="container">
                            <div className="wrapper">
                                <div className="imageContainer">
                                    <img src={spinwin} alt="Spin and win" ref={divref}/>
                                </div>
                                <motion.div className="textContainer" style={{ y }} >
                                    <h2>Old Mutual Spin and Win</h2>
                                    <ul className="programs">
                                        
                                        <li>React JS</li>
                                        <li>TypeScript</li>
                                        <li>HTML</li>
                                        <li>Tailwind CSS</li>
                                        <li>Node JS</li>
                                        <li>Figma</li>
                                        
                                    </ul>
                                    <p>Old Mutual's Digital Adoption campaign aimed to persuade their customers to embrace their digital banking platforms, thereby reducing in-branch traffic. The campaign featured a digital 'Spin n Win' wheel, which I helped design and develop in collaboration with Old Mutual through the agency Black Creative Ideas. Additionally, I worked on developing their web app to host the wheel. The competition spanned a duration of three months.</p>
                                    <p style={{fontWeight:"bold", color:"#92e3a9"}}>Resource material classified</p>
                                   
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    {items.map(item => (
                        <Single item={item} key={item.id}/>
                    ))}
                </div>
            </div>
        </section>
     );
}
 
export default Projects;

